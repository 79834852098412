import React, { useContext, useEffect, useState } from "react";
import InputFormGroup from "../../components/InputFormGroup";
import { updateSocialHandles } from "../../utils/database/settingsHandler";
import { SettingsContext } from "../../utils/context";
import { settingsType } from "../../types";


type socialsType = {
 facebook: string;
 twitter: string;
 instagram: string;
 developer: string;
 website: string;
 faqs: string
};


const Socials = () => {
  const [socialUrls, setSocailUrls] = useState<socialsType>();

  const {settings } = useContext<settingsType | any>(SettingsContext);

  useEffect(() => {
    if (settings) {
      setSocailUrls(settings["socials"]);
    }
  }, [settings]);


  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | Element | any>
  ): void => {
    setSocailUrls((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateSocialHandles(socialUrls);

  };

  return (
    <div className="row mx-2">
      <div className="col-sx-12 col-md-6  bg-white rounded-3 p-3">
        <h5 className="mb-2">Socials</h5>
        <form onSubmit={onSubmit}>
          <InputFormGroup
            name="facebook"
            onChange={onChange}
            id="facebook"
            inputType="url"
            value={socialUrls?.facebook ?? ""}
            labelText="Facebook"
          />
          <InputFormGroup
            name="twitter"
            onChange={onChange}
            id="twitter"
            inputType="url"
            value={socialUrls?.twitter ?? ""}
            labelText="Twitter"
          />
          <InputFormGroup
            name="instagram"
            onChange={onChange}
            id="instagram"
            inputType="url"
            value={socialUrls?.instagram ?? ""}
            labelText="Instagram"
          />
          <InputFormGroup
            name="developer"
            onChange={onChange}
            id="developer"
            inputType="url"
            value={socialUrls?.developer ?? ""}
            labelText="Developer"
          />
          <InputFormGroup
            name="website"
            onChange={onChange}
            id="website"
            inputType="url"
            value={socialUrls?.website ?? ""}
            labelText="Official Website"
          />
          <InputFormGroup
            name="faqs"
            onChange={onChange}
            id="faqs"
            inputType="url"
            value={socialUrls?.faqs ?? ""}
            labelText="Frequently asked questions"
          />

          <button type="submit" className="btn btn-sm btn-primary-dark">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Socials;
