import { toast } from "react-toastify";
import { orderType } from "../../types";
import { database } from "../dbConfig";

import {
  collection,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";

export const getOrders = async (
  setOrders: React.Dispatch<React.SetStateAction<orderType[]>>
) => {
  try {
    onSnapshot(collection(database, "orders"), (snapshot: any) => {
      if (snapshot) {
      } else {
        setOrders([]);
      }
    });
  } catch (error: any) {
    console.log(error.message);
  }
};

export const addOrder = async (uid: string, orderData: orderType) => {
  try {
    await setDoc(doc(collection(database, "orders"), uid), orderData);
  } catch (error) {
    console.log(error);
  }
};


export const updateOrder = async(id:string, orderData: orderType) => {
    try {
        await updateDoc(doc(collection(database, "orders"),id),{});
        toast.success("Order updated successfully")
    } catch (error) {
        console.log(error)
        
    }

}