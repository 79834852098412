import React from 'react'

type selectGroupType = {
  htmlFor?: string;
  labelText?: string;
  name: string;
  id?: string;
  placeholder? : string;
  required?: boolean;
  defaultValue?: string | null | any;
  onChange: (e: React.ChangeEvent) => void;
  list: any[];
  disabled?: boolean;
};

const SelectFormGroup = ({
  htmlFor,
  labelText,
  name,
  id = "",
  onChange,
  list = [],
  defaultValue = null,
  required = false,
  disabled = false,
  placeholder = ""
}: selectGroupType) => {
  return (
    <>
      {labelText && (
        <div className="mb-2">
          <label htmlFor={htmlFor} className="form-label small text-capitalize">
            {labelText}
          </label>
        </div>
      )}

      <select
        className="form-select form-select-sm text-capitalize small"
        id={id}
        name={name}
        onChange={onChange}
        required={required}
        disabled={disabled}
        defaultValue={defaultValue ? defaultValue : ""}
      >
        <option value="">{placeholder}</option>
        {list.map((item: string, index: number) => (
          <option
            key={index}
            selected={
              String(defaultValue).toLowerCase() === String(item).toLowerCase()
            }
            value={item}
          >
            {item}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectFormGroup