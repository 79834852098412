import React, { useContext, useState } from "react";
import logo_black from "../assets/images/logo-black.jpg";
import { loginWithEmailAndPassword, signupUserWithEmailAndPassword } from "../utils/database/authHandler";
import { CurrentUserContext } from "../utils/context";

type formDataType = {
  email: string;
  password: string;
};
type formErrorType = {
  emailError: string;
  passwordError: string;
};
const SignIn = () => {
  const [formData, setFormData] = useState<formDataType | any>();
  const [formError, setformError] = useState<formErrorType | any>();
  const [loading, setLoading] = useState(false);

  const { setCurrentUser } = useContext(CurrentUserContext);
  
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setformError({ emailError: "", passwordError: "" });
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!formData?.email) {
      setformError((prev: any) => ({
        ...prev,
        emailError: "Email field is required",
      }));
      return;
    }
    if (!formData?.password) {
      setformError((prev: any) => ({
        ...prev,
        passwordError: "Password filed is required",
      }));
      return;
    }
    loginWithEmailAndPassword(formData?.email, formData?.password, setLoading, setCurrentUser);
    // signupUserWithEmailAndPassword(
    //   formData?.email,
    //   formData?.password,
    //   setLoading
    // );
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="row w-75 rounded-5">
        <div className="col-md-6 bg-white p-5">
          <img src={logo_black} width="80" />
          <h1 className="fw-bold text-center mb-2">Hi admin!</h1>
          <div className="text-muted mb-4 text-center">
            Welcome to Mofyri administrative dashboard
          </div>

          <form onSubmit={onSubmit}>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                id="email"
                required={true}
                onChange={onChange}
                placeholder="Email address"
                className="form-control"
                disabled={loading}
              />
              {formError?.emailError && (
                <div className="text-danger small mt-2">
                  {formError?.emailError}
                </div>
              )}
            </div>

            <div className="mb-4">
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={onChange}
                disabled={loading}
                className="form-control"
              />
              {formError?.passwordError && (
                <div className="text-danger small mt-2">
                  {formError?.passwordError}
                </div>
              )}
            </div>
            <div className="mb-5">
              <button
                disabled={loading}
                className="btn btn-sm btn-primary-dark w-100 rounded-5 py-2"
              >
                {loading === false ? (
                  "Login"
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      className="spinner-grow spinner-grow-sm text-white"
                      role="status"
                    />
                    <div className="ms-2">Logging in....</div>
                  </div>
                )}
              </button>
            </div>
            <div className="text-muted mb-5 text-center small">
              <span className="small">
                Caution! You're actions beyond here might break the app
              </span>
            </div>
          </form>
        </div>
        <div
          className="col-md-6"
          style={{
            background:
              "url(https://shorturl.at/wFKU9) no-repeat scroll center / cover",
          }}
        ></div>
      </div>
    </div>
  );
};

export default SignIn;
