import { ArrowRight2, Edit, Edit2, Trash } from "iconsax-react";
import React, { useContext, useEffect, useState } from "react";
import InputFormGroup from "../../components/InputFormGroup";
import { addDeliveryLocations, addExtraService } from "../../utils/database/settingsHandler";
import { deliveryLocationType } from "../../types";
import { DeliveryLocationsContext, SettingsContext } from "../../utils/context";

const DeliveryLocations = () => {
  const [selectedWidget, setSelectedWidget] = useState(0);

  const list = ["Delivery & markup", "location", "More services"];
  const wigetList = [<DeliveryAndMarkup />, <Locations />, <ExtraServices />];
  return (
    <div className="row px-2">
      <div className="col-ms-12 col-md-3">
        <div className="bg-white rounded-2">
          {list.map((item: String, index: number) => (
            <div
              onClick={() => setSelectedWidget(index)}
              className="border-bottom p-3 text-capitalize d-flex align-items-center justify-content-between small"
              style={{ cursor: "pointer" }}
              key={index}
            >
              {item}
              <ArrowRight2 size={15} />
            </div>
          ))}
        </div>
      </div>
      <div className="col-ms-12 col-md-9">
        <div className=" bg-white rounded-2 p-4">
          {wigetList[selectedWidget]}
        </div>
      </div>
    </div>
  );
};

export default DeliveryLocations;

const DeliveryAndMarkup = () => {
  const [loading, setLoading] = useState(false);

  const { settings } = useContext(SettingsContext);
  const [formData, setFormData] = useState<any>();

  useEffect(() => {
    if (settings) {
      setFormData({
        generalDelivery: settings["generalDelivery"]["fee"],
        markup: settings["markup"]["fee"],
      });
    }
  }, [settings]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | any>) => {};
  return (
    <div className="row">
      <div className="col-md-6">
        {" "}
        <form>
          <InputFormGroup
            inputType="number"
            name="deliveryFee"
            id="name"
            labelText="General delivery fee"
            onChange={onChange}
            disabled={loading}
            value={formData?.generalDelivery}
          />
          <InputFormGroup
            inputType="number"
            name="markup"
            id="markup"
            labelText="Markup price"
            onChange={onChange}
            value={formData?.markup}
          />
          <button className="btn btn-primary-dark btn-sm w-100">
            Save changes
          </button>
        </form>
      </div>
    </div>
  );
};

const Locations = () => {
  const iconSize = 15;
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState<deliveryLocationType>({
    name: "",
    fee: 0,
  });
  const [loading, setLoading] = useState(false);

  const { deliveryLocations, setDeliveryLocations } = useContext(
    DeliveryLocationsContext
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement | any>): void => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    addDeliveryLocations(formData, setLoading);
  };
  return (
    <>
      <h6>Location</h6>
      <div className="row">
        <div className="col-md-6">
          <table className="table">
            <thead>
              <tr>
                {["#", "name", "fee", ""].map((item: string, index: number) => (
                  <td key={index} className="text-capitalize small text-muted">
                    {item}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {deliveryLocations?.map(
                (location: deliveryLocationType, index: number) => (
                  <tr key={location.id} className="small text-capitalize">
                    <td>{index + 1}</td>
                    <td>{location?.name}</td>
                    <td>{location?.fee}</td>
                    <td>
                      <div className="d-flex gap-3 align-items-end justify-content-end">
                        <Edit
                          size={iconSize}
                          onClick={() => {
                            setFormData(location);
                            setIsEdit(true);
                          }}
                        />
                        <Trash size={iconSize} />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <div className="">Add new location</div>
          <form onSubmit={onSubmit}>
            <InputFormGroup
              inputType="text"
              name="name"
              id="name"
              value={formData.name}
              required={true}
              labelText="Name"
              onChange={onChange}
              disabled={loading}
            />
            <InputFormGroup
              inputType="text"
              name="fee"
              id="fee"
              value={formData.fee}
              required={true}
              labelText="Delivery fee"
              onChange={onChange}
              disabled={loading}
            />
            <button type="submit" className="btn btn-sm btn-primary-dark w-100">
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

type formTyme = { name: string; };

const ExtraServices = () => {
  const [formData, setFormData] = useState<formTyme>({ name: ""});
  const { settings} = useContext<any>(SettingsContext);
   
  const onChange = (e: React.ChangeEvent<HTMLInputElement | any>): void => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    console.log(formData);

    addExtraService(formData);
  };
  console.log(settings);
  return (
    <>
      <h6>Services</h6>
      <div className="row">
        <div className="col-md-6">
          <table className="table">
            <thead>
              <tr>
                {[
                  "#",
                  "Name",
                  ""
                ].map((item: string, index: number) => (
                  <th className="text-capitalize small" key={index}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.values(settings?.extraServices)?.map(
                (service: any, index: number) => (
                 <tr className="small text-muted text-capitalize">
                  <td>{index + 1}</td>
                  <td>{service.name}</td>
                  <td><Edit2 size={14}/> </td>
                 </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <div className="text-muted">
            <strong>Add new service</strong>
          </div>
          <form onSubmit={onSubmit}>
            <InputFormGroup
              inputType="text"
              name="name"
              id="name"
              value={formData.name}
              labelText="Name"
              onChange={onChange}
              required={true}
            />

            <button type="submit" className="btn btn-sm btn-primary-dark w-100">
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
