import clsx from "clsx";

const Pagination = ({
  pages,
  handlePrevious,
  onHandleChange,
  handleNext,
  currentPage,
  maxPageNumberLimit,
  minPageNumberLimit,
} : any) => {
  return (
    <>
      <div className="btn-group" role="group">
        <button
          onClick={() => handlePrevious()}
          disabled={currentPage === pages[0]}
          className="btn btn-sm btn-outline-primary-dark  "
        >
          Previous
        </button>
        {pages.map((number: number) =>
          number < maxPageNumberLimit + 1 && number > minPageNumberLimit ? (
            <button
              onClick={() => onHandleChange(number)}
              className={clsx(
                "btn btn-sm btn-outline-primary-dark  ",
                number === currentPage ? "active" : ""
              )}
              key={number}
            >
              {number}
            </button>
          ) : null
        )}
        <button
          className="btn btn-sm btn-outline-primary-dark  "
          disabled={currentPage === pages[pages.length - 1]}
          onClick={() => handleNext()}
        >
          <span >Next</span>
        </button>
      </div>
    </>
  );
};


export default Pagination;
