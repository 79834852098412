import { auth } from "../dbConfig";
import {
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { redirect } from 'react-router-dom'

type errorType = {
  error: string;
  message: string;
};
export const signupUserWithEmailAndPassword = async (
  email: string,
  password: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    const user = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    ).then((userCredentials) => {
      // const user = userCredentials.user
      return userCredentials.user;
    });

    setLoading(false);
  } catch (error: errorType | any) {
    setLoading(false);
    const errorCode = error?.code;
    const errorMessage = error.message;
  }
};

export const loginWithEmailAndPassword = async (
  email: string,
  password: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentUser: React.Dispatch<React.SetStateAction<User | any>>
) => {
  try {
    setLoading(true);
    let user = await signInWithEmailAndPassword(auth, email, password).then(
      (userCredentials) => userCredentials.user
    );
    if (user && user?.uid) setCurrentUser({ ...user });
    else setCurrentUser(null);
    setLoading(false);
  } catch (error: errorType | any) {
    setLoading(false);
    const errorCode = error?.code;
    const errorMessage = error.message;
  }
};

export const logout = async (
  
) => {
  try {
    await auth.signOut();
    redirect("/signin");

  } catch (error: any) {

console.log(error.message)  }
};
