import React, { useContext } from 'react'
import { BrandsContext } from '../utils/context';
import InputFormGroup from './InputFormGroup';
import SelectFormGroup from './SelectFormGroup';
import rimSize from "../utils/rawData/rimSize.json";
import terrain from "../utils/rawData/terrain.json";
import vehicleType from "../utils/rawData/vehicle.json";
import seasonsList from "../utils/rawData/seasons.json";




type propsType = {
  onSearchParams : React.ChangeEvent<HTMLInputElement | any>
};
const SearchProduct: React.FC<propsType | any> = ({
  onSearchParams,
}) => {
  const { brands } = useContext(BrandsContext);

  const extractBrandNames = () => {
    let brandNames: string[] = [];
    if(brands)
    for (let brand of brands) {
      brandNames.push(brand.name);
    }
    return brandNames;
  };

  return (
    <div className="bg-white p-3 mx-2 rounded-3 ">
      <div className="my-2 row row-cols-3 gy-3">
        <div className="col">
          <input
            name="name"
            type="text"
            id="name"
            className="form-control form-control-sm"
            placeholder="Search by name"
            onChange={onSearchParams}
          />
        </div>

        <div className="col">
          {" "}
          <SelectFormGroup
            placeholder="Filter by type"
            name="type"
            id="type"
            list={vehicleType}
            onChange={onSearchParams}
          />
        </div>

        <div className="col">
          {" "}
          <SelectFormGroup
            placeholder="Filter by brand"
            name="type"
            id="type"
            list={extractBrandNames()}
            onChange={onSearchParams}
          />
        </div>
        <div className="col">
          <SelectFormGroup
            placeholder="Filter by rim size"
            name="type"
            id="type"
            list={rimSize}
            onChange={onSearchParams}
          />
        </div>
        <div className="col">
          <SelectFormGroup
            placeholder="Filter by terrain"
            name="terrain"
            id="terrain"
            list={terrain}
            onChange={onSearchParams}
          />
        </div>
        <div className="col">
          <SelectFormGroup
            placeholder="Filter by seasons"
            name="season"
            id="season"
            list={seasonsList}
            onChange={onSearchParams}
          />
        </div>
        <div className="col-12 d-flex align-items-end justify-content-between w-50 m-end gap-4 mt-3">
          <button className="btn btn-sm btn-primary-yellow w-75">Search</button>
          <button className="btn btn-sm btn-outline-primary-yellow">Discard</button>
        </div>
      </div>
    </div>
  );
};

export default SearchProduct;

