import { DocumentSnapshot } from "firebase/firestore";
import {
  brandType,
  categoryType,
  customerType,
  deliveryLocationType,
  paymentOptionType,
  tyreType,
} from "../types";
import { generateRandomNumbers } from "../utils/functions";

export class TyreModel {
  toDatabase(data: tyreType) {
    return {
      id: data.id,
      name: String(data.name).toUpperCase(),
      brand: String(data.brand).toLowerCase(),
      terrain: String(data?.terrain || "").toUpperCase(),
      rimSize: Number(data.rimSize),
      price: Number(data.price),
      discountPrice: Number(data?.discountPrice) || 0,
      description: data.description || "",
      imageUrl: data.imageUrl || null,
      vehicleType: data.vehicleType,
      showIn: data.showIn || [],
      dateAdded: data.dateAdded,
      stock: Number(data.stock),
      updatedDate: data.updatedDate || null,
      stock_availability: data.stock_availability,
      isFeatured: data.isFeatured || false,
      season: data.season,
      type: data.type || null,
    };
  }
}

export class CustomerModel {
  toDatabase(customer: customerType) {
    return {
      uid: customer.uid,
      avatar: customer.avatar || null,
      accountType: customer.accountType,
      fullName: customer.fullName,
      email: customer.email,
      phone: customer.phone,
      addresses: customer.accountType,
      dateCreated: customer.dateCreated,
      dateUpdated: Date.now(),
      accountStatus: customer.accountStatus,
      verified: customer.verified || false,
    };
  }
}

export class BrandsModel {
  toDatabase(brand: brandType) {
    return {
      id: brand.id,
      name: brand.name,
      description: brand.description || "",
      rating: Number(brand.rating) || 0,
      imageUrl: brand.imageUrl || "",
      country: brand.country || "",
      status: brand.status || "active",
      dateAdded: brand?.dateAdded,
      dateUpdated: brand?.dateUpdated || "",
    };
  }

  formDatabase(brand: brandType | any) {
    return {
      id: brand.id,
      name: brand.name,
      description: brand.description,
      rating: Number(brand.rating),
      country: brand.country,
      status: brand.status,
      imageUrl: brand.imageUrl,
      dateAdded: brand?.dateAdded,
      dateUpdated: brand?.dateUpdated,
    };
  }
}

export class CategoryModel {
  toDatabase(category: categoryType) {
    return {
      id: category.id,
      name: category.name,
      imageUrl: category.imageUrl || null,
      dateAdded: Date.now(),
      updatedDate: category.updatedDate || null,
    };
  }
  fromDatabase(snapshot: any) {
    return {
      id: snapshot["id"],
      name: snapshot["name"],
      imageUrl: snapshot["imageUrl"],
      dateAdded: snapshot["dateAdded"],
      updatedDate: snapshot["updatedDate"],
    };
  }
}

export class PaymentOptionModel {
  toDatabase(paymentOption: paymentOptionType) {
    return {
      id: paymentOption.id,
      providetImage: paymentOption.providetImage,
      providerName: paymentOption.providerName,
      providerDescriptiveName: paymentOption.providerDescriptiveName,
      status: paymentOption.status,
      dateAdded: paymentOption.dateAdded,
    };
  }
  fromDatabase(snapshot: any) {
    return {
      id: snapshot["id"],
      providetImage: snapshot["providetImage"],
      providerName: snapshot["providerName"],
      providerDescriptiveName: snapshot["providerDescriptiveName"],
      status: snapshot["status"],
      dateAdded: snapshot["dateAdded"],
    };
  }

  updateDatabase(paymentOption: paymentOptionType) {
    return {
      providetImage: paymentOption.providetImage,
      providerName: paymentOption.providerName,
      providerDescriptiveName: paymentOption.providerDescriptiveName,
      status: paymentOption.status,
    };
  }
}

export class DeliveryLocation {
  fromDatabase(snapshot: any) {
    return {
      id: snapshot["id"],
      name: snapshot["name"],
      fee: snapshot["fee"],
    };
  }
  toDatabase(location: deliveryLocationType) {
    return {
      id: location.id,
      name: location.name,
      fee: Number(location.fee),
    };
  }
}
