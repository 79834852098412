import React, { useContext, useEffect, useState } from "react";
import { ArrowSquareLeft, Edit, Gallery } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import InputFormGroup from "../components/InputFormGroup";
import { Rating } from "react-simple-star-rating";
import { addBrand, updateBrand } from "../utils/database/brandHandler";
import { BrandsContext } from "../utils/context";
import { brandType } from "../types";
import SelectFormGroup from "../components/SelectFormGroup";
import countries from "../utils/rawData/countries.json";
import Pagination from "../components/Pagination";

const Brands = () => {

  const pages : any [] = [];
  const navigate = useNavigate();
    const { brands } = useContext(BrandsContext);


  const [tempImage, setTempImage] = useState({ url: "", image: null });
  const [formLoadingState, setFormLoadingState] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [filteredBrands, setFilteredBrands] = useState<brandType[]>();


  const [isEditing, setIsEditing] = useState(false);

   const [currentPage, setCurrentPage] = useState(1);
   const [rowsPerPage, setRowsPerPage] = useState(15);
   const [pageNumberLimit, setPageNumberLimit] = useState(5);
   const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
   const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

   const indexOfLastItem = currentPage * rowsPerPage;
   const indexOfFirstItem = indexOfLastItem - rowsPerPage;

  useEffect(() => {
    if (brands) setFilteredBrands(brands);
  }, [brands]);

    if (filteredBrands)
      for (
        let i = 1;
        i <= Math.ceil(filteredBrands?.length / rowsPerPage);
        i++
      ) {
        pages.push(i);
      }

  const browseImage = (e: React.ChangeEvent | any) => {
    let fileUrl = URL.createObjectURL(e.target.files[0]);
    setTempImage({ url: fileUrl, image: e.target.files[0] });
  };

  const handleChange = (e: React.ChangeEvent | any) => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRating = (rating: number) => {
    setFormData((prev: any) => ({ ...prev, rating }));
  };

  const handleSearch = (e: React.ChangeEvent | any) => {
    let searchedBrands = brands.filter((brand: brandType) =>
      String(brand.name).toLowerCase().includes(e.target.value)
    );
    setFilteredBrands(searchedBrands);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isEditing) {
      addBrand(formData, tempImage.image, setFormLoadingState);
    } else {
      updateBrand(formData, tempImage.image, setFormLoadingState);
    }
  };

  const handleFilterByCountryOrStatus = (e: React.ChangeEvent | any) => {
    if (e.target.value) {
      let searchedBrands: brandType[] = [];
      if (e.target.name === "countryFilter")
        searchedBrands = brands.filter(
          (brand: brandType) =>
            String(brand.country).toLowerCase() === e.target.value.toLowerCase()
        );
      else
        searchedBrands = brands.filter(
          (brand: brandType) =>
            String(brand.status).toLowerCase() === e.target.value.toLowerCase()
        );

      setFilteredBrands(searchedBrands);
    } else {
      setFilteredBrands(brands);
    }
  };

    const handlePrevious = () => {
      if (currentPage === 0) return;
      if (currentPage === 1) {
        setCurrentPage(0);
      }
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
      if ((currentPage - 1) % pageNumberLimit === 0) {
        setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
    };

    const handleNext = () => {
      setCurrentPage(currentPage + 1);

      if (currentPage + 1 > maxPageNumberLimit) {
        setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
      }
    };
    const onHandleChange = (pageValue: number) => {
      setCurrentPage(pageValue);
    };

  return (
    <>
      <div className="p-3 bg-white">
        <div className="d-flex align-items-start gap-3">
          <ArrowSquareLeft
            size="18"
            color="#33333370"
            onClick={() => navigate(-1)}
          />
          {/* <ArrowLeft size="18" color="#333" className="border p-2" /> */}

          <h6>Brands</h6>
        </div>
      </div>

      <div className="container p-4">
        <div className="row">
          <div className="col-md-7 col-12">
            <div className="d-flex align-items-center gap-2 bg-white mb-3 px-3 pt-3 rounded-2">
              <InputFormGroup
                inputType="search"
                name="search"
                id="search"
                placeholder={"Search by name"}
                inputClass="form-control form-control-sm"
                onChange={handleSearch}
              />
              <div className="mb-3">
                <SelectFormGroup
                  name="countryFilter"
                  id="countryFilter"
                  placeholder="Filter by country"
                  onChange={handleFilterByCountryOrStatus}
                  list={countries}
                />
              </div>
              <div className="mb-3">
                <SelectFormGroup
                  name="statusFilter"
                  id="statusFilter"
                  placeholder="Filter by status"
                  onChange={handleFilterByCountryOrStatus}
                  list={["active", "disabled"]}
                />
              </div>
            </div>
            <div className="bg-white  p-3 py-4 rounded-2">
              {filteredBrands && (
                <table className="table table-bordered">
                  <thead>
                    <tr className="small">
                      {" "}
                      {["#", "name", "country", "rating", "Status", ""].map(
                        (item: string, index: number) => (
                          <th className="small text-capitalize" key={index}>
                            {item}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {(rowsPerPage > 0
                      ? filteredBrands?.slice(indexOfFirstItem, indexOfLastItem)
                      : filteredBrands
                    ).map((brand: brandType, index: number) => (
                      <tr key={brand?.id} className="small">
                        <td>{index + 1}</td>
                        <td className="text-capitalize">{brand?.name}</td>
                        <td className="text-capitalize">{brand?.country}</td>
                        <td>{brand?.rating}</td>
                        <td>{brand?.status}</td>
                        <td align="center">
                          <Edit
                            size="15"
                            onClick={() => {
                              setFormData({ ...brand });
                              setIsEditing(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className="d-flex justify-content-center my-3">
                <Pagination
                  pages={pages}
                  handlePrevious={handlePrevious}
                  onHandleChange={onHandleChange}
                  handleNext={handleNext}
                  currentPage={currentPage}
                  maxPageNumberLimit={maxPageNumberLimit}
                  minPageNumberLimit={minPageNumberLimit}
                />
              </div>
            </div>
          </div>
          <div className="col-md-5 col-12">
            <div className="bg-white p-3 py-4 rounded-2">
              <h6 className="pb-2 mb-2">Add new brand</h6>

              <form onSubmit={handleSubmit}>
                <div className="border  p-2 mb-3 d-flex gap-3">
                  <div className="img_placeholder bg-color-1 py-3 px-4">
                    <div
                      style={{
                        background: `url(${
                          tempImage.url || formData?.imageUrl
                        }) no-repeat scroll center / cover`,
                        width: "80px",
                        height: "80px",
                      }}
                    />
                  </div>
                  <label
                    htmlFor="uploadfile"
                    className="img_btn btn rounded-2 d-flex align-items-center flex-column justify-content-center py-3 px-4 "
                  >
                    <Gallery size="25" color="#000000A2" />

                    <div className="small mt-2" style={{ fontSize: "11px" }}>
                      Click to browse
                    </div>
                  </label>
                </div>
                <input
                  id="uploadfile"
                  type="file"
                  accept="image/*"
                  className="hide_input"
                  onChange={browseImage}
                />
                <InputFormGroup
                  name="name"
                  inputType="text"
                  labelText={"Brand Name"}
                  id="name"
                  value={formData?.name}
                  inputClass="form-control form-control-sm"
                  htmlFor="name"
                  required={true}
                  disabled={formLoadingState}
                  onChange={handleChange}
                />
                <SelectFormGroup
                  labelText="country"
                  htmlFor="country"
                  id="country"
                  name="country"
                  required={true}
                  defaultValue={formData?.country}
                  onChange={handleChange}
                  list={countries}
                />
                <label className="">Description</label>
                <textarea
                  id="description"
                  name="description"
                  onChange={handleChange}
                  value={formData?.description}
                  className="form-control form-control-sm"
                  rows={5}
                  disabled={formLoadingState}
                />
                <label className="form-label mt-3">Star Rating</label>
                <div>
                  {" "}
                  <Rating
                    transition={true}
                    showTooltip={true}
                    allowFraction={true}
                    initialValue={formData?.rating || 0}
                    size={25}
                    tooltipDefaultText="Unrated"
                    tooltipStyle={{ fontSize: "11px" }}
                    onClick={handleRating}

                    /* Available Props */
                  />
                </div>
                <label className="form-label mt-3">Status</label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="status"
                    checked={
                      String(formData?.status).toLowerCase() === "active"
                    }
                    value={formData?.status}
                    onChange={(e) => {
                      setFormData((prev: any) => ({
                        ...prev,
                        status:
                          e.target.checked === true ? "active" : "disabled",
                      }));
                    }}
                  />
                  <label className="form-check-label" htmlFor="status">
                    Active
                  </label>
                </div>
                <div className="d-flex align-items-center gap-4 border-top mt-4 pt-3">
                  <button
                    type="submit"
                    className="btn btn-primary-yellow btn-sm"
                  >
                    Add brand
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm"
                    onClick={() => {
                      setFormData({});
                      setIsEditing(false);
                    }}
                  >
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
