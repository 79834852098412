import { createContext } from "react";
import { brandType, categoryType, tyreType } from "../../types";

type tyreContextType = {
  tyres: null | any[];
  setTyres: React.Dispatch<React.SetStateAction<tyreType[] | any>>;
};

type customerContextType = {
  customers: null;
  setCustomers: React.Dispatch<React.SetStateAction<any[] | any>>;
};

type orderContextType = {
  orders: null;
  setOrders: React.Dispatch<React.SetStateAction<any[] | any>>;
};
export const TyresContext = createContext<tyreContextType>({
  tyres: null,
  setTyres: () => {},
});

export const OrdersContext = createContext<orderContextType>({
  orders: null,
  setOrders: () => {},
});

export const CustomersContext = createContext<customerContextType[] | any>({
  customers: null,
  setCustomers: () => {},
});

export const MessagesContext = createContext(null);

type categoryContextType = {
  categories: categoryType[] | null;
  setCategories: React.Dispatch<React.SetStateAction<categoryType[]>>;
};
export const CategoryContext = createContext<categoryContextType>({
  categories: null,
  setCategories: () => {},
});

type brandContextType = {
  brands: null;
  setBrands: React.Dispatch<React.SetStateAction<brandType[]>>;
};

export const BrandsContext = createContext<brandContextType | any>({
  brands: null,
  setBrands: () => {},
});

type currentUserContextType = {
  currentUser: null;
  setCurrentUser: React.Dispatch<React.SetStateAction<null>>;
};

export const CurrentUserContext = createContext<currentUserContextType>({
  currentUser: null,
  setCurrentUser: () => {},
});

type paymentOptionContextType = {
  paymentOptions: null;
  setPaymentOptions: React.Dispatch<React.SetStateAction<null>>;
};
export const PaymentOptionContext = createContext<paymentOptionContextType>({
  paymentOptions: null,
  setPaymentOptions: () => {},
});

type deliveryLocationContextType = {
  deliveryLocation: null;
  setDeliveryLocations : React.Dispatch<React.SetStateAction<null | any>>;
}
export const DeliveryLocationsContext = createContext<deliveryLocationContextType | any>({
  deliveryLocations: null,
  setDeliveryLocations: () => {},
});

type settingsContextType = {
  settings: null;
  setSettings: React.Dispatch<React.SetStateAction<null | any>>;
};
export const SettingsContext = createContext<settingsContextType>({
  settings: null,
  setSettings: () => {},
});