import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { CurrentUserContext } from "../utils/context";

const ProtectedRoutes = ({ children }: any) => {
  const location = useLocation();
  const { currentUser } = useContext(CurrentUserContext);

  if (!currentUser)
    return <Navigate to="/signin" state={{ from: location }} replace />;
  return children;
};

export default ProtectedRoutes;
