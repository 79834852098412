import React from 'react';
import SidebarNav from '../components/Sidebar';
import { Outlet } from 'react-router-dom';
import TopNav from '../components/TopNav';




const MainLayout = () => {

    ///check if user exists

  return (
    <div className="container my-5 ">
      <div className="d-flex align-items-start ">
        <SidebarNav />
        <div className="main flex-fill" style={{minHeight:"90vh"}}>
          <TopNav/>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout