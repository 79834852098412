import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { brandType } from "../../types";
import { database, storage } from "../dbConfig";
import {
  doc,
  setDoc,
  updateDoc,
  collection,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { BrandsModel } from "../../model";
import { toast } from "react-toastify";

const brandsModel = new BrandsModel();

export const getBrands = async (
  setBrands: React.Dispatch<React.SetStateAction<any>>
) => {
  try {
    onSnapshot(collection(database, "brands"), (snapshot) => {
      if (snapshot) {
        let brands: brandType[] = [];

        snapshot.forEach((doc: any) => {
          let brandData = brandsModel.formDatabase(doc.data());
          brands.push(brandData);
        });
        setBrands(brands);
      } else {
        setBrands([]);
      }
    });
  } catch (error: any) {
    console.log(error.message);
  }
};

export const addBrand = async (
  brand: brandType,
  image: any,
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoadingState(true);
  let brand_id = "";
  for (let i = 0; i < 10; i++) {
    brand_id += String(Math.floor(Math.random() * 9));
  }
  try {
    if (image) {
      //upload to firebase Storage
      let storageRef = ref(storage, `images/brands/${brand_id}`);
      let uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snaphos) => {
          //track upload status
        },
        (err: any) => {
          //throw error
          throw new Error(err);
        },
        () =>
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            const brandData = brandsModel.toDatabase({
              ...brand,
              imageUrl: downloadURL,
              id: brand_id,
              dateAdded: Date.now(),
            });
            await setDoc(
              doc(collection(database, "brands"), `${brand_id}`),
              brandData
            );
          })
      );
    } else {
      const brandData = brandsModel.toDatabase({
        ...brand,
        id: brand_id,
        dateAdded: Date.now(),
      });
      await setDoc(
        doc(collection(database, "brands"), `${brand_id}`),
        brandData
      );
    }
    setLoadingState(false);
    toast.success("Brand added suuccessfully");
  } catch (error) {
    setLoadingState(false);
  }
};

export const updateBrand = async (
  brand: brandType,
  image: any,
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoadingState(true);
    if (image) {
      //upload to firebase Storage
      let storageRef = ref(storage, `images/brands/${brand.id}`);
      let uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snaphos) => {
          //track upload status
        },
        (err: any) => {
          //throw erro
          throw new Error(err);
        },
        () =>
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            const brandData = brandsModel.toDatabase({
              ...brand,
              imageUrl: downloadURL,
              dateUpdated: Date.now(),
            });
            await updateDoc(
              doc(collection(database, "brands"), `${brand.id}`),
              brandData
            );
          })
      );
    } else {
      const brandData = brandsModel.toDatabase({
        ...brand,
        dateUpdated: Date.now(),
      });

      await updateDoc(doc(collection(database, "brands"), brand.id), brandData);
    }
    setLoadingState(false);
    toast.success("Brand updated successfully");
  } catch (err: any) {
    console.log(err);
    toast.error(err.messaga);
  }
};