import { Add, Edit, Sort, Trash } from 'iconsax-react';
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { CustomersContext } from '../utils/context';
import { customerType } from '../types';

const Customers = () => {
  const { customers } = useContext(CustomersContext);

  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-between bg-white p-3 mb-2">
        <div> Customers</div>
        <div className="d-flex align-items-center gap-3">
          <Sort size="22" color="#00000095" />
          <Link to="/customer/new" className="btn btn-sm btn-primary-yellow">
            <Add size="18" />
            <span className="small">New Customer</span>
          </Link>
        </div>
      </div>
      <div className="container">
        {customers && customers?.length > 0 ? (
          <table className="table">
            <thead>
              <tr
                className="small text-capitalize"
                style={{ fontWeight: "500" }}
              >
                {[
                  "#",
                  "Full name",
                  "Account type",
                  "Email",
                  "Phone",
                  "Status",
                  " verified",
                  "Actions",
                ].map((item: any, index: number) => (
                  <td className="small" key={index}>
                    {item}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {customers?.map((customer: customerType, index: number) => (
                <tr key={customer.uid} className="small">
                  <td
                    style={{ verticalAlign: "middle", fontWeight: "500" }}
                    className="small"
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="small text-capitalize"
                  >
                    <img
                      src={customer?.avatar || "https://shorturl.at/fouAE"}
                      style={{ width: "30px" }}
                      className="rounded-circle profile_pic me-2"
                    />{" "}
                    <span>{customer.fullName}</span>
                  </td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="small text-capitalize"
                  >
                    {customer.accountType}
                  </td>
                  <td style={{ verticalAlign: "middle" }} className="small">
                    {customer.email}
                  </td>
                  <td style={{ verticalAlign: "middle" }} className="small">
                    {customer.phone}
                  </td>
                  <td
                    style={{ verticalAlign: "middle" }}
                    className="small text-capitalize"
                  >
                    {customer.accountStatus}
                  </td>
                  <td style={{ verticalAlign: "middle" }} className="small">
                    {customer.verified}
                  </td>
                  <td>
                    <button className="btn btn-sm">
                      <Edit size="13" />
                    </button>
                    <button className="btn btn-sm">
                      {" "}
                      <Trash size="13" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : !customers ? (
          "Loading"
        ) : (
          "There is no data"
        )}
      </div>
    </div>
  );
}

export default Customers