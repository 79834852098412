import { storage, database } from "../dbConfig";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {
  doc,
  setDoc,
  collection,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { CategoryModel } from "../../model";
import { categoryType } from "../../types";

export const addCategory = async (
  formData: categoryType,
  image: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    let category_id = "";
    for (let i = 0; i < 10; i++) {
      category_id += String(Math.floor(Math.random() * 9));
    }
    if (image) {
      let storageRef = ref(storage, `images/categories/${category_id}`);
      let uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error: any) => {
          throw new Error(error);
        },
        () =>
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadUrl) => {
            await setDoc(
              doc(collection(database, "categories"), category_id),
              new CategoryModel().toDatabase({
                ...formData,
                id: category_id,
                imageUrl: downloadUrl,
              })
            );
          })
      );
    } else {
      await setDoc(
        doc(collection(database, "categories"), category_id),
        new CategoryModel().toDatabase({
          ...formData,
          id: category_id,
        })
      );
    }
    setLoading(false);
    toast.success("Category added successfully");
  } catch (error) {
    setLoading(false);
    toast.error("Category failed");
    console.log(error);
  }
};

export const updateCategory = async (
  formData: categoryType | any,
  image: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    if (image) {
      let storageRef = ref(storage, `images/categories/${formData.id}`);
      let uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error: any) => {
          throw new Error(error);
        },
        () =>
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadUrl) => {
            await updateDoc(
              doc(collection(database, "categories"), formData.id),
              {
                ...formData,
                imageUrl: downloadUrl,
              }
            );
          })
      );
    } else {
      await updateDoc(
        doc(collection(database, "categories"), formData.id),
        formData
      );
    }
    setLoading(false);
    toast.success("Category updated successfully");
  } catch (error) {
    setLoading(false);
    toast.error("Category update failed");
  }
};

export const getCategories = async (
  setCategories: React.Dispatch<React.SetStateAction<categoryType[]>>
) => {
  try {
    onSnapshot(collection(database, "categories"), (snapshot) => {
      if (snapshot) {
        let categories: categoryType[] = [];
        snapshot.forEach((doc: any) => {
          categories.push(doc.data());
        });
        setCategories(categories);
      }
    });
  } catch (error) {
    console.log(error);
  }
};
