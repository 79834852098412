import { ArrowRight2 } from "iconsax-react";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import InputFormGroup from "../../components/InputFormGroup";
import { addPaymentOptionSettings, updateDeliveryMarkupFee, updatePaymentOptionSettings } from "../../utils/database/settingsHandler";
import { SettingsContext } from "../../utils/context";

const PaymentOptions = () => {
  const options = ["pesapal", "flutterwave"];
  const widgets = [<PesapalWidget />, <Flutterwave />];
  const [active, setActive] = useState(0);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-3">
        <div className="inner bg-white rounded-2 ">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={() => setActive(index)}
              className="border-bottom p-3 text-capitalize d-flex align-items-center justify-content-between small"
              style={{ cursor: "pointer" }}
            >
              {option}
              <ArrowRight2 size={15} />
            </div>
          ))}
        </div>
      </div>
      <div className="col-sm-12 col-md-9">
        <div className="bg-white p-3 rounded-3">{widgets[active]}</div>
      </div>
    </div>
  );
};

export default PaymentOptions;

export const PesapalWidget = () => {
  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const {settings} = useContext(SettingsContext)
  useEffect(() => {
     if(settings){
      setFormData(settings["paymentOptions"]["pesapal"]);
     }
  },[settings]);
  const onSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    console.log(formData);
    //formt data;
    const data = {
      pesapal: {
        ...formData,
      },
    };

    updatePaymentOptionSettings(data, setLoading);
    // addPaymentOptionSettings(data, setLoading);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement | any>): void => {
    if (e.target.id.startsWith("demo")) {
      setFormData((prev: any) => ({
        ...prev,
        sandbox: {
          ...prev["sandbox"],
          [e.target.name]: e.target.value,
        },
      }));
    } else if (e.target.id.startsWith("live")) {
      setFormData((prev: any) => ({
        ...prev,
        live: {
          ...prev["live"],
          [e.target.name]: e.target.value,
        },
      }));
    } else if (e.target.name === "go_live")
      setFormData((prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    else
      setFormData((prev: any) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="row ">
          <div className="col-sm-12 col-md-6">
            <h6 className="text-muted">Sandbox</h6>
            <InputFormGroup
              inputType="text"
              name="api_key"
              id="demo_api_key"
              placeholder={"Enter api key"}
              onChange={onChange}
              labelText="Api key"
              disabled={loading}
              value={formData?.sandbox?.api_key}
            />
            <InputFormGroup
              inputType="text"
              name="secret_key"
              id="demo_secret_key"
              placeholder={"Enter api key"}
              onChange={onChange}
              labelText="Api Secrete"
              disabled={loading}
              value={formData?.sandbox?.secret_key}
            />
            <InputFormGroup
              inputType="text"
              name="api_endPoint"
              id="demo_api_endPoint"
              placeholder={"Enter sandbox endpoint"}
              onChange={onChange}
              labelText="Api Endpoint"
              disabled={loading}
              value={formData?.sandbox?.api_endPoint}
            />
          </div>
          <div className="col-md-6">
            <h6 className="text-muted">Live</h6>
            <InputFormGroup
              inputType="text"
              name="api_key"
              id="live_api_key"
              placeholder={"Enter api key"}
              onChange={onChange}
              labelText="Api key"
              value={formData?.live?.api_key}
            />
            <InputFormGroup
              inputType="text"
              name="secret_key"
              id="live_secret_key"
              placeholder={"Enter api key"}
              onChange={onChange}
              labelText="Api Secrete"
              disabled={loading}
              value={formData?.live?.secret_key}
            />
            <InputFormGroup
              inputType="text"
              name="api_endPoint"
              id="live_api_endPoint"
              placeholder={"Enter live endpoint"}
              onChange={onChange}
              labelText="Api Endpoint"
              disabled={loading}
              value={formData?.live?.api_endPoint}
            />
          </div>
        </div>
        <h6 className="text-muted mt-4">Call backs</h6>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <InputFormGroup
              inputType="text"
              name="success_callbak"
              id="success_callbak"
              placeholder={"Enter success callback"}
              onChange={onChange}
              labelText="Success callbak"
              disabled={loading}
              value={formData?.success_callbak}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <InputFormGroup
              inputType="text"
              name="cancel_callback"
              id="cancel_callback"
              placeholder={"Enter cancel callback"}
              onChange={onChange}
              labelText="Cancel callback"
              disabled={loading}
              value={formData?.cancel_callback}
            />
          </div>
        </div>
        <div className="form-check form-switch mb-4 mt-3">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            name="go_live"
            id="check_live"
            onChange={onChange}
            disabled={loading}
            checked={formData?.go_live}
          />
          <label
            className="form-check-label small fw-bold text-muted"
            htmlFor="check_live"
          >
            Go Live
          </label>
        </div>
        <div className="d-flex align-items-center gap-4">
          <button
            type="submit"
            className="btn btn-primary-yellow btn-sm rounded-0"
            disabled={loading}
          >
            Submit
          </button>
          <button
            type="button"
            className="btn btn-primary-dark btn-sm rounded-0"
            disabled={loading}
          >
            Clear
          </button>
        </div>
      </form>
    </>
  );
};

export const Flutterwave = () => {
  return <div>Flutter waave</div>;
};
