import { toast } from "react-toastify";
import { customerType } from "../../types";
import { database, storage } from "../dbConfig";
import {
  collection,
  onSnapshot,
  getDocs,
  getDoc,
  query,
  where,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { CustomerModel } from "../../model";

const customerModel = new CustomerModel();
export const getCustomers = (
  setCustomers: React.Dispatch<React.SetStateAction<any[]>>
) => {
  try {
    const querySnap = query(
      collection(database, "users"),
      where("accountType", "==", "customer")
    );
    onSnapshot(querySnap, (querySnapshot) => {
      const customers: any[] = [];
      querySnapshot.forEach((doc: any) => {
        customers.push(doc.data());
      });
      setCustomers(customers);
    });
  } catch (error: any) {
    console.log(error);
    toast.error(error.message);
  }
};

export const updateCustomer = async (
  formData: customerType,
  image: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    const customer = customerModel.toDatabase(formData);
    if (image) {
      const storageRef = ref(storage, `images/customers/${formData.uid}`);
      let uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error: any) => {
          throw new Error(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            await updateDoc(
              doc(collection(database, "users"), formData.uid),
              { ...customer, avatar: downloadURL }
            );
          });
        }
      );
    } else {
      await updateDoc(
        doc(collection(database, "users"), formData.uid),
        customer
      );
    }
    toast.success("Customer data updated successfully");
    setLoading(false);
  } catch (error: any) {
    console.log(error);
    toast.error(error.message);
    setLoading(false);
  }
};

export const deleteCustomer = async (
  id: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    await deleteDoc(doc(collection(database, "users"), id));

    toast.success("Customer deleted successfully");

    setLoading(false);
  } catch (error: any) {
    console.log(error);
    toast.error(error.message);
    setLoading(false);
  }
};
