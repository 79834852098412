import { TyreModel } from "../../model";
import { tyreType } from "../../types";
import { database, storage } from "../dbConfig";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { toast } from "react-toastify";

const tyreModel = new TyreModel();

export const addTyre = async (
  tyre: tyreType,
  image: any,
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoadingState(true);
  let tyre_id = "";
  for (let i = 0; i < 10; i++) {
    tyre_id += String(Math.floor(Math.random() * 9));
  }
  try {
    if (image) {
      //upload to firebase Storage
      let storageRef = ref(storage, `images/tyres/${tyre_id}`);
      let uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snaphot) => {
          //track upload status
        },
        (err: any) => {
          //throw error
          throw new Error(err);
        },
        () =>
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            const tyreData = tyreModel.toDatabase({
              ...tyre,
              imageUrl: downloadURL,
              id: tyre_id,
              dateAdded: Date.now(),
            });
            await setDoc(
              doc(collection(database, "tyres"), `${tyre_id}`),
              tyreData
            );
          })
      );
    } else {
      const tyreData = tyreModel.toDatabase({
        ...tyre,
        id: tyre_id,
        dateAdded: Date.now(),
      });
      await setDoc(doc(collection(database, "tyres"), `${tyre_id}`), tyreData);
    }
    setLoadingState(false);
    toast.success("Tyre added suuccessfully");
  } catch (error) {
    setLoadingState(false);
  }
};

export const updateTyre = async (
  tyre: tyreType,
  image: any,
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoadingState(true);
    if (image) {
      //upload to firebase Storage
      let storageRef = ref(storage, `images/tyres/${tyre.id}`);
      let uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snaphos) => {
          //track upload status
        },
        (err: any) => {
          //throw erro
          throw new Error(err);
        },
        () =>
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            const tyreData = tyreModel.toDatabase({
              ...tyre,
              imageUrl: downloadURL,
              updatedDate: Date.now(),
            });
            await updateDoc(
              doc(collection(database, "tyres"), `${tyre.id}`),
              tyreData
            );
          })
      );
    } else {
      const tyreData = tyreModel.toDatabase({
        ...tyre,
        updatedDate: Date.now(),
      });

      await updateDoc(doc(collection(database, "tyres"), tyre.id), tyreData);
    }
    setLoadingState(false);
    toast.success("Tyre updated successfully");
  } catch (err: any) {
    console.log(err);
    toast.error(err.messaga);
  }
};

export const getTyre = async (id: string) => {
  try {
  } catch (error) {}
};

export const getTyres = async (
  setTyres: React.Dispatch<React.SetStateAction<tyreType[]>>
) => {
  try {
    onSnapshot(collection(database, "tyres"), (querySnaphot) => {
      const tyres: any[] = [];
      querySnaphot.forEach((doc: any) => {
        tyres.push(doc.data());
      });
      setTyres(tyres);
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteTyre = async (
  id: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    await deleteDoc(doc(collection(database, "tyres"), id));
    toast.success("Tyre deleted successfully");
    setLoading(false);
  } catch (err: any) {
    console.log(err.message);
    toast.error(err.message);
  }
};
