import clsx from "clsx";
import { Add, Edit, Sort } from "iconsax-react";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { OrdersContext } from "../utils/context";
import dummyOrders from "../utils/dummy/dummyOrders.json";
import { orderType } from "../types";
import InputFormGroup from "../components/InputFormGroup";

const Orders = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { orders } = useContext(OrdersContext);

  const handleChange = (e:React.ChangeEvent) => {}

  return (
    <>
      <div className=" bg-white pt-3 pb-1 px-3 mb-1">
        <h5>Orders</h5>
      </div>
      <div className="container p-3">
        <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
          <div className="tab_wrapper d-flex align-items-center gap-4 text-capitalize small">
            {["All orders", "pending", "completed"].map(
              (item: string, index: number) => (
                <div
                  key={index}
                  className={clsx(
                    "tab_ pb-3 small",
                    index === activeTab && "active"
                  )}
                  onClick={() => setActiveTab(index)}
                >
                  {item}
                </div>
              )
            )}
          </div>
          <div className="float-end">
            <input
              type="search"
              id="search"
              name="search"
              className="form-control form-control-sm"
              placeholder="Search by code"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="bg-white">
          <table className="table">
            <thead>
              <tr className="small text-capitalize">
                {[
                  "# code",
                  "customer",
                  "items",
                  "Amount paid",
                  "delivery fee",
                  "Order status",
                  "delivery status",
                  "payment method",
                  "",
                ].map((item: string, index: number) => (
                  <td
                    className="small"
                    key={index}
                    style={{ fontWeight: "500" }}
                  >
                    {item}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody className="small">
              {dummyOrders.map((order: orderType | any, index: number) => (
                <tr className="small text-capitalize" key={index}>
                  <td>{order.id}</td>
                  <td>{order.customer.name}</td>
                  <td>{JSON.stringify(order.items)}</td>
                  <td>{order.totalAmount}</td>
                  <td>{order.deliveryFee}</td>
                  <td>{order.orderStatus}</td>
                  <td>{order.deletingStatus}</td>
                  <td>Mobile money</td>
                  <td>
                    <Link
                      to="/order/details"
                      state={order}
                      className="btn btn-sm"
                    >
                      <Edit size="13" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Orders;
