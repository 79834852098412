import React, { useContext } from 'react'
import { CurrentUserContext } from '../utils/context'
import { Navigate, useLocation } from 'react-router-dom';

const AnnonymousOnlyRoutes = ({children} : any) => {
   const location = useLocation();
    const { currentUser} = useContext(CurrentUserContext);

    if(currentUser) return <Navigate to="/" state={{ from: location }} replace />;
  return children;
}

export default AnnonymousOnlyRoutes