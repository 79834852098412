import { ArrowRight, ArrowRight2 } from "iconsax-react";
import React, { useContext, useEffect, useState } from "react";
import PaymentMethods from "../components/PaymentMethods";
import clsx from "clsx";
import About from "../components/About";
import Faqs from "../components/Faqs";
import Developer from "../components/Developer";
import Socials from "./settings/Socials";
import PaymentOptions from "./settings/PaymentOptions";
import DeliveryLocations from "./settings/DeliveryLocations";
import { SettingsContext } from "../utils/context";
import { getSettings } from "../utils/database/settingsHandler";

const Settings = () => {
  const [activeWidget, setActiveWidget] = useState(0);
  const menu_list = [
    { name: "payment options" },
    { name: "Delivery & locations" },
    { name: "Social & other" },
    { name: "markups" },
  ];
  const { settings, setSettings} = useContext(SettingsContext)
  useEffect(() => {
     if(!settings){
      getSettings(setSettings);
     }
  });

  const widget_list = [
    <PaymentOptions />,
    <DeliveryLocations />,
    <Socials />,
    <Faqs />,
    <Developer />,
    <Socials />,
  ];
  return (
    <>
      <div className=" bg-white pt-3 pb-1 px-3 mb-1">
        <h5>Settings</h5>
      </div>
      <div className="d-flex align-items-center mt-4 ms-3 gap-3 text-capitalize mb-3">
        {menu_list.map((item: any, index: number) => (
          <div
            className={clsx("small", activeWidget === index && "active", "pb-2")}
            style={{
              cursor: "pointer",
              borderBottom: `2px solid  ${activeWidget === index ? '#101820' : 'transparent'}`,
            }}
            key={index}
            onClick={() => setActiveWidget(index)}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className="mx-2">{widget_list[activeWidget]}</div>
      {/* <div className="row vh-100">
        <div className="col-12 col-md-3 bg-white">
          <ul className="list-group list-group-flush 100vh">
            {menu_list.map((item: any, index: number) => (
              <li
                key={index}
                onClick={() => setActiveWidget(index)}
                style={{ cursor: "pointer" }}
                className={clsx(
                  "list-group-item d-flex justify-content-between align-items-center",
                  activeWidget === index && "active"
                )}
              >
                <span className="small text-capitalize"> {item.name}</span>
                <ArrowRight2 size={15} />
              </li>
            ))}
          </ul>
        </div>
        <div className="col-12 col-md-9">{widget_list[activeWidget]}</div>
      </div> */}
    </>
  );
};

export default Settings;
