import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { deleteTyre, getTyres } from "../utils/database/tyreHandler";
import { Add, Edit, Sort, Trash } from "iconsax-react";
import { BrandsContext, CategoryContext, TyresContext } from "../utils/context";
import { brandType, categoryType, tyreType } from "../types";
import Moment from "react-moment";
import InputFormGroup from "../components/InputFormGroup";
import SelectFormGroup from "../components/SelectFormGroup";
import SearchProduct from "../components/SearchProduct";
import Pagination from "../components/Pagination";
import { currentFormatter } from "../utils/functions";
import clsx from "clsx";

const Products = () => {


  const { tyres } = useContext(TyresContext);
  const { brands } = useContext(BrandsContext);
  const { categories } = useContext(CategoryContext);

  const pages: any[] = [];
  const [deletingStatus, setDeletingStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(13);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [isSearchPanelCollapsed, setIsSearchPanelCollapsed] = useState(false);

  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;

  const getBrandName = (id: string) => {
    let brandName = brands.filter((brand: brandType) => brand.id === id)[0]
      .name;

    return brandName;
  };
  const onSearchParams = (e: React.ChangeEvent) => {};

  if (tyres)
    for (let i = 1; i <= Math.ceil(tyres?.length / rowsPerPage); i++) {
      pages.push(i);
    }
  const handlePrevious = () => {
    if (currentPage === 0) return;
    if (currentPage === 1) {
      setCurrentPage(0);
    }
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const onHandleChange = (pageValue: number) => {
    setCurrentPage(pageValue);
  };
  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-between bg-white p-3 mb-2">
        <div> Products</div>
        <div className="d-flex align-items-center gap-3">
          <Sort style={{cursor:"pointer"}} size="22" color="#00000095" onClick={() => setIsSearchPanelCollapsed(prev => !prev)}/>
          <Link to="/product/new" className="btn btn-sm btn-primary-yellow">
            <Add size="18" />
            <span className="small">Add Product</span>
          </Link>
        </div>
      </div>
      <div className={clsx("search_panel",isSearchPanelCollapsed && "collapsed")}>
        <SearchProduct onSearchParams={onSearchParams} />
      </div>

      <div className="container pt-2">
        {tyres && tyres?.length > 0 ? (
          <>
            <table className="table ">
              <thead>
                <tr>
                  {[
                    "#",
                    "Name",
                    "type",
                    "brand",
                    "Rim size",
                    "price",
                    "terrain",
                    "season",
                    "Added",
                    "Action",
                  ].map((item: string, index: number) => (
                    <th className="text-capitalize small" key={index}>
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(rowsPerPage > 0
                  ? tyres.slice(indexOfFirstItem, indexOfLastItem)
                  : tyres
                ).map((tyre: tyreType, index: number) => {
                  let vehicleType = categories?.find(
                    (cat: categoryType) => cat.id === tyre.vehicleType
                  );
                  return (
                    <tr key={index} className="text-capitalize small">
                      <td
                        style={{ verticalAlign: "middle", fontWeight: "700" }}
                      >
                        {index + 1}
                      </td>
                      <td className="d-flex align-items-center">
                        <img
                          alt={tyre?.brand}
                          src={tyre.imageUrl || "https://shorturl.at/dmxGW"}
                          width="50"
                          className="me-2"
                          style={{ maxWidth: "50px" }}
                        />
                        {tyre.name}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {vehicleType?.name}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {getBrandName(tyre?.brand)}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {tyre?.rimSize}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {currentFormatter(tyre?.price)}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {tyre?.terrain}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {tyre?.season}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <Moment date={tyre?.dateAdded} format="DD/MM/YYYY" />
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <Link
                          to="/product/new"
                          state={tyre}
                          className="btn btn-sm"
                        >
                          <Edit size="15" />
                        </Link>
                        <button
                          onClick={() =>
                            deleteTyre(tyre?.id, setDeletingStatus)
                          }
                          className="btn btn-sm"
                        >
                          <Trash size="15" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-center my-3">
              <Pagination
                pages={pages}
                handlePrevious={handlePrevious}
                onHandleChange={onHandleChange}
                handleNext={handleNext}
                currentPage={currentPage}
                maxPageNumberLimit={maxPageNumberLimit}
                minPageNumberLimit={minPageNumberLimit}
              />
            </div>
          </>
        ) : tyres === null ? (
          "Loading"
        ) : (
          "There is no data"
        )}
      </div>
    </div>
  );
};

export default Products;
