import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo-icon-default.png";
import wordart from "../assets/images/wordart-black.png";
import {
  ElementPlus,
  Logout,
  Message,
  Setting2,
  StatusUp,
  TruckFast,
  UserOctagon,
} from "iconsax-react";
import { logout } from "../utils/database/authHandler";

const SidebarNav = () => {
  let iconSize = "18";
  let variant: "Bold" | "Linear" | "Outline" = "Outline";

  const mainMenu = [
    {
      title: "Dashboard",
      url: "/",
      icon: <ElementPlus size={iconSize} variant={variant} />,
    },
    {
      title: "Orders",
      url: "/orders",
      icon: <TruckFast size={iconSize} variant={variant} />,
    },
    {
      title: "Tyres",
      url: "/products",
      icon: <StatusUp size={iconSize} variant={variant} />,
    },
    {
      title: "Brands",
      url: "/brands",
      icon: <StatusUp size={iconSize} variant={variant} />,
    },
    {
      title: "Categories",
      url: "/categories",
      icon: <StatusUp size={iconSize} variant={variant} />,
    },
    {
      title: "Customers",
      url: "/customers",
      icon: <UserOctagon size={iconSize} variant={variant} />,
    },
    {
      title: "Messages",
      url: "/messages",
      icon: <Message size={iconSize} variant={variant} />,
    },
  ];
  const userMenu = [
    {
      title: "My account",
      url: "/my-account",
      icon: <Message size={iconSize} variant={variant} />,
    },
    {
      title: "My account",
      url: "/my-account",
      icon: <Message size={iconSize} variant={variant} />,
    },
  ];
  const actionMenu = [
    {
      title: "Settings",
      url: "/settings",
      icon: <Setting2 size={iconSize} variant={variant} />,
    },
    { title: "logout", icon: <Logout size={iconSize} variant={variant} /> },
  ];

  let submenuStyle: any = {
    color: "",
    fontSize: "12px",
    textTransform: "uppercase",
    fontWeight: "600",
    opacity: "0.6",
  };
  return (
    <Sidebar
      className="bg-white"
      style={{ minHeight: "90vh", position: "sticky", top: "0" }}
    >
      <div className="d-flex align-items-center gap-3 p-3 border-bottom">
        <img src={logo} style={{ width: "40px", height: "auto" }} />
        <img src={wordart} style={{ height: "15px", width: "auto" }} />
      </div>

      <Menu>
        <SubMenu
          label="Admin"
          defaultOpen={true}
          active={true}
          style={{ ...submenuStyle }}
        >
          {mainMenu.map((menuItem: any, index: number) => (
            <MenuItem key={index} component={<Link to={menuItem.url} />}>
              <div className="d-flex align-items-center gap-2">
                {menuItem.icon}
                <div className="small">{menuItem.title}</div>
              </div>
            </MenuItem>
          ))}
        </SubMenu>
        <SubMenu
          label="Account"
          defaultOpen={true}
          active={true}
          style={{ ...submenuStyle }}
        >
          {userMenu.map((menuItem: any, index: number) => (
            <MenuItem key={index} component={<Link to={menuItem.url} />}>
              <div className="d-flex align-items-center gap-2">
                {menuItem.icon}
                <div className="small">{menuItem.title}</div>
              </div>
            </MenuItem>
          ))}
        </SubMenu>
        <SubMenu
          label="Preferences"
          defaultOpen={true}
          active={true}
          style={{ ...submenuStyle }}
        >
          {actionMenu.map((menuItem: any, index: number) => (
            <MenuItem
              key={index}
              component={
                menuItem.title === "logout" ? (
                  <div onClick={() => logout()} />
                ) : (
                  <Link to={menuItem.url} />
                )
              }
            >
              <div className="d-flex align-items-center gap-2">
                {menuItem.icon}
                <div className="small">{menuItem.title}</div>
              </div>
            </MenuItem>
          ))}
        </SubMenu>
      </Menu>
    </Sidebar>
  );
};

export default SidebarNav;
