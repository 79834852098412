import { User } from "iconsax-react";
import React, { useContext, useEffect } from "react";
import { BrandsContext, CategoryContext, CustomersContext, DeliveryLocationsContext, OrdersContext, TyresContext } from "../utils/context";
import { getTyres } from "../utils/database/tyreHandler";
import { getCustomers } from "../utils/database/customerHandler";
import { getOrders } from "../utils/database/orderHandler";
import { getBrands } from "../utils/database/brandHandler";
import { getCategories } from "../utils/database/categoryHandler";
import { getDeliveryLocations } from "../utils/database/settingsHandler";

const TopNav = () => {
  const { tyres, setTyres } = useContext(TyresContext);
  const { customers, setCustomers } = useContext(CustomersContext);
  const { orders, setOrders } = useContext(OrdersContext);
  const { brands, setBrands} = useContext(BrandsContext);
  const { categories, setCategories } = useContext(CategoryContext);
  const { deliveryLocations, setDeliveryLocations} = useContext(DeliveryLocationsContext);
  useEffect(() => {
    if (!tyres) getTyres(setTyres);
    if (!customers) getCustomers(setCustomers);
    if(!orders) getOrders(setOrders);
    if(!brands) getBrands(setBrands);
    if(!categories) getCategories(setCategories);
    if(!deliveryLocations) getDeliveryLocations(setDeliveryLocations);
  }, []);
  return (
    <div className="tob-bar bg-white border-bottom">
      <div className="container py-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="crumbs">Home / dashboard</div>
          <div className="d-flex align-items-center gap-2 small">
            <span className="small text-muted">Hi, Admin</span>
            <User size="20" color="#000" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
