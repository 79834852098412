import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import tyre from "../assets/images/tyre.png";
import { currentFormatter } from "../utils/functions";
import { Sort } from "iconsax-react";
import SelectFormGroup from "../components/SelectFormGroup";

type tracker = {
  status: string;
  message: string;
};
const OrderDetails = () => {
  let order = useLocation().state;

  const [tracker, setTrackker] = useState<tracker | any>();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | any>
  ) => {
    setTrackker((prev: tracker) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //update order with tracker
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between bg-white p-3 mb-2">
        <div className="px-3"> Order details</div>
      </div>

      <div className="container ">
        <div className="bg-white m-2 mt-4 p-3">
          <h6 className="border-bottom pt-2 pb-3 mb-4">Order: #{order.id}</h6>

          <div className="row row-cols-md-4 row-cols-12 mb-3 rounded">
            <div>
              <div className="small text-uppercase _lble">
                Order information
              </div>
              <div className="_lble_">Order status</div>
              <div className="_lbl">{order.orderStatus}</div>
              <div className="_lble_">Total amount</div>
              <div className="_lbl">{currentFormatter(order.totalAmount)}</div>
              <div className="_lble_">Subtotal</div>
              <div className="_lbl">
                {currentFormatter(order.subtotatAmount)}
              </div>
            </div>
            <div>
              <div className="_lble mb-4 pt-2"></div>
              <div className="_lble_">Delivery fee</div>
              <div className="_lbl">{currentFormatter(order.deliveryFee)}</div>
              <div className="_lble_">Delivery status</div>
              <div className="_lbl">{order.tracking[0].status}</div>
              <div className="_lble_">Payment method</div>
              <div className="_lbl">Mobile money</div>
            </div>
            <div>
              <div className="_lble mb-4 pt-2"></div>
              <div className="_lble_">Location</div>
              <div className="_lbl">{order.deliveryLocation}</div>
              <div className="_lble_">Payment status</div>
              <div className="_lbl">Mobile money</div>
              <div className="_lble_">Date ordered</div>
              <div className="_lbl">{order.dateCreated}</div>
            </div>
            <div>
              <div className="small text-uppercase _lble">
                Customer information
              </div>
              <div className="_lble_">Full name</div>
              <div className="_lbl">{order.customer.name}</div>
              <div className="_lble_">Email address</div>
              <div className="_lbl text-lowercase">{order.customer.email}</div>
              <div className="_lble_">phone number</div>
              <div className="_lbl">{order.customer.phone}</div>
            </div>
          </div>
        </div>
        <div className="p-2 mx-2 mt-3 bg-white rounded">
          <div className="lnt  d-flex justify-content-between px-2 py-3">
            <span>Item list</span>
            <span className="small">count {order.items.length}</span>
          </div>
          <table className="table table-bordered small ">
            <thead>
              <tr>
                {["NO", "Item name", "Quantity", "unit price"].map(
                  (item: string, index: number) => (
                    <th key={index} className="small">
                      <div className="px-3 text-capitalize">{item}</div>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody className="small">
              {order.items.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="px-4" style={{ verticalAlign: "middle" }}>
                    {index + 1}
                  </td>
                  <td className="px-4" style={{ verticalAlign: "middle" }}>
                    <img
                      src={tyre}
                      className="me-2"
                      style={{ width: "35px", height: "auto" }}
                    />
                    {item.name}
                  </td>
                  <td className="px-4" style={{ verticalAlign: "middle" }}>
                    x{item.quantity}
                  </td>
                  <td className="px-4" style={{ verticalAlign: "middle" }}>
                    {currentFormatter(item.pricePerUnit)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bg-white m-2 mt-4 p-3">
          <div className="lnt">Order tracker</div>
          <div className="row ">
            <div className="col">Status</div>
            <div className="col">
              Update tracker
              <form onSubmit={handleSubmit}>
                <SelectFormGroup
                  labelText="status"
                  htmlFor="status"
                  name="status"
                  id="status"
                  onChange={handleChange}
                  list={[
                    "pending",
                    "recieved",
                    "dispatched",
                    "delivered",
                    "canceled",
                  ]}
                />
                <label
                  htmlFor="message"
                  className="form-label small text-capitalize mt-2"
                >
                  Message
                </label>
                <textarea
                  placeholder="message"
                  rows={5}
                  name="message"
                  id="message"
                  className="form-control"
                />
                <div className="d-flex align-items-center gap-4 mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary-yellow btn-sm  px-4"
                  >
                    Update tracker
                  </button>
                  <button className="btn btn-outline-primary-dark btn-sm">
                    Discard
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
