import { useContext, useState } from "react";
import InputFormGroup from "../components/InputFormGroup";
import { Edit, Gallery } from "iconsax-react";
import { addCategory, updateCategory } from "../utils/database/categoryHandler";
import { CategoryContext } from "../utils/context";
import { categoryType } from "../types";


const Categories = () => {
  const [tempImage, setTempImage] = useState({ url: "", image: null });
  const [formData, setFormData] = useState<categoryType | any>(null);
  const [loading, setLoading] = useState(false);
  const [errorForm, setErrorForm] = useState<string | null>(null);

  const [isEditing, setIsEditing] = useState(false);

  const { categories } = useContext(CategoryContext);

  console.log(categories);
  const handleChange = (e: React.ChangeEvent | any) => {
    if (errorForm) {
      setErrorForm(null);
    }
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onSubmit = (e: React.FormEvent): void => {
    e.preventDefault();

    if (formData) {
            if (!isEditing) 
                addCategory(formData, tempImage.image, setLoading);
            else updateCategory(formData, tempImage.image, setLoading);
    } else {
      if (!formData.name) {
        setErrorForm("Please enter a valid Name");
      }
    }
  };

  const browseImage = (e: React.ChangeEvent | any): void => {
    console.log(e.target.files[0]);
    let fileUrl = URL.createObjectURL(e.target.files[0]);
    setTempImage({ url: fileUrl, image: e.target.files[0] });
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between bg-white p-3 mb-2">
        <div> Categories</div>
      </div>

      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            {categories ? (
              <table className="table table-">
                <thead className="small">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {categories?.map((category: categoryType, index: number) => (
                    <tr key={category?.id} className="text-capitalize">
                      <td className="small text-muted" style={{verticalAlign:"middle", textAlign:"center"}}>{index + 1}</td>
                      <td>
                        <img
                          src={category?.imageUrl}
                          width={100}
                          className="rounded-3"
                        />{" "}
                        {category?.name}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <button className="btn" onClick={() =>{ 
                            setFormData(category);
                            setIsEditing(true);
                            }}><Edit size={14} /></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              "Loading"
            )}
          </div>
          <div className="col-md-6">
            <div className="bg-white p-3">
              <h6 className="my-3 border-bottom pb-2 text-capitalize">
               {!isEditing ? "Add new" : "update"}  category
              </h6>
              <form onSubmit={onSubmit}>
                <label className="form-label small me-3">Category Image</label>
                {loading && (
                  <span className="small text-danger">Loading...</span>
                )}
                <div className="border  p-2 mb-3 d-flex gap-3">
                  <div className="img_placeholder bg-color-1 ">
                    <div
                    className="rounded-2"
                      style={{
                        background: `url(${
                          tempImage.url ||
                          formData?.imageUrl ||
                          "https://shorturl.at/dmxGW"
                        }) no-repeat scroll center / cover`,
                        width: "200px",
                      
                        aspectRatio:"2/1.5"
                      }}
                    />
                  </div>
                  <label
                    htmlFor="uploadfile"
                    className="img_btn btn rounded-2 d-flex align-items-center flex-column justify-content-center py-3 px-4 "
                  >
                    <Gallery size="25" color="#000000A2" />

                    <div className="small mt-2" style={{ fontSize: "11px" }}>
                      Click to browse
                    </div>
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    id="uploadfile"
                    className="hide_input"
                    onChange={browseImage}
                    disabled={loading}
                  />
                </div>
                <div className="mb-3" />
                <InputFormGroup
                  name="name"
                  inputType="text"
                  labelText="Category name"
                  htmlFor="name"
                  id="name"
                  value={formData?.name}
                  disabled={loading}
                  required={true}
                  onChange={handleChange}
                />
                {errorForm && (
                  <div className="text-danger small">{errorForm}</div>
                )}
                <button
                  className="btn btn-sm btn-primary-yellow w-100 mt-3"
                  type="submit"
                  disabled={loading}
                >
                  {!loading ? "Submit" : "Laoding"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Categories;
