import { ArrowLeft, ArrowSquareLeft, Gallery } from "iconsax-react";
import React, { useContext, useEffect, useState } from "react";
import InputFormGroup from "../components/InputFormGroup";
import SelectFormGroup from "../components/SelectFormGroup";
import tyreImage from "../assets/images/tyre.png";
import { brandType, tyreType } from "../types";
import { addTyre, updateTyre } from "../utils/database/tyreHandler";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { BrandsContext, CategoryContext } from "../utils/context";
import rimSize from "../utils/rawData/rimSize.json";
import vehicleType from "../utils/rawData/vehicle.json";

type formDataType = {
  formData: tyreType | any;
  setFormData: React.Dispatch<React.SetStateAction<tyreType>>;
};
let defaultSchema = {};

const AddProducts: React.FC = () => {
  const navigate = useNavigate();
  const tyreToEdit = useLocation().state;
  const { brands } = useContext(BrandsContext);
  const [formData, setFormData] = useState<tyreType | any>({
    showIn: [],
  });
  const [tempImage, setTempImage] = useState({ url: "", image: null });
  const [loading, setLoading] = useState(false);

  const { categories } = useContext(CategoryContext);

  useEffect(() => {
    if (tyreToEdit) setFormData(tyreToEdit);
  }, [tyreToEdit]);

  const extraBrandNames = () => {
    let brandNames: string[] = [];
    if (brands)
      for (let brand of brands) {
        brandNames.push(brand.name);
      }

    return brandNames;
  };


  const browseImage = (e: React.ChangeEvent | any) => {
    let fileUrl = URL.createObjectURL(e.target.files[0]);
    setTempImage({ url: fileUrl, image: e.target.files[0] });
  };
  const handleChange = (e: React.ChangeEvent | any): void => {
      setFormData((prev: tyreType | any) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
  };

  const onSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    if (tyreToEdit) updateTyre(formData, tempImage.image, setLoading);
    else addTyre(formData, tempImage.image, setLoading);
  };
  return (
    <>
      <div className="p-3 bg-white">
        <div className="d-flex align-items-start gap-3">
          <ArrowSquareLeft
            size="18"
            color="#33333370"
            onClick={() => navigate(-1)}
          />
          {/* <ArrowLeft size="18" color="#333" className="border p-2" /> */}

          <h6>{tyreToEdit?.id ? "Edit tyre" : "New tyre"}</h6>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="row p-3">
          <div className="col-md-6">
            <div className="bg-white   p-3">
              <h6>Description</h6>
              <div className="border p-2 mb-3">
                <InputFormGroup
                  htmlFor="name"
                  inputType="text"
                  labelText="Tyre Name"
                  name="name"
                  value={formData?.name}
                  id="id"
                  required={true}
                  inputClass="form-control form-control-sm"
                  onChange={handleChange}
                  disabled={loading}
                />

                <div className="mb-3">
                  <label htmlFor="description" className="form-label small">
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    className="form-control"
                    onChange={handleChange}
                    value={formData?.description}
                    disabled={loading}
                  />
                </div>
              </div>
              <h6>Category</h6>
              <div className="border mb-3 p-2">
                <div className="mb-3">
                  <div className="mb-2">
                    <label
                      htmlFor="vehicleType"
                      className="form-label small text-capitalize"
                    >
                      Brand
                    </label>
                  </div>

                  <select
                    id="brand"
                    name="brand"
                    required={true}
                    onChange={handleChange}
                    disabled={loading}
                    className="form-select form-select-sm text-capitalize"
                  >
                    <option value=""></option>
                    {brands?.map((brand : brandType) => (
                      <option
                        selected={formData?.brand === brand.id}
                        value={brand.id}
                      >
                        {brand.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <SelectFormGroup
                    htmlFor="terrain"
                    labelText="terrain"
                    name="terrain"
                    id="terrain"
                    defaultValue={formData?.terrain}
                    required={false}
                    onChange={handleChange}
                    list={["mt", "at", "hw"]}
                    disabled={loading}
                  />
                </div>

                <div className=" mb-3">
                  <div className="mb-2">
                    <label
                      htmlFor="vehicleType"
                      className="form-label small text-capitalize"
                    >
                      Vehicle Type
                    </label>
                  </div>

                  <select
                    id="vehicleType"
                    name="vehicleType"
                    required={true}
                    onChange={handleChange}
                    disabled={loading}
                    className="form-select form-select-sm text-capitalize"
                  >
                    <option value=""></option>
                    {categories?.map((category) => (
                      <option
                        selected={formData?.vehicleType === category.id}
                        value={category.id}
                      >
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <h6>Tyre type</h6>
              <div className="border p-2 mb-3">
                <SelectFormGroup
                  htmlFor="type"
                  labelText="type"
                  name="type"
                  id="type"
                  defaultValue={formData?.type}
                  required={true}
                  onChange={handleChange}
                  list={["private", "commercial"]}
                  disabled={loading}
                />
              </div>
              <h6>Show in</h6>
              <div className="border p-2 mb-3">
                {["popular brands", "most selling", "top brands"].map(
                  (item: string | any, index: number) => (
                    <div className="form-check mb-1" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={formData?.showIn.includes(item)}
                        value={item}
                        disabled={loading}
                        id={item.toString().replace(/ +/g, "_")}
                        onChange={() => {
                          if (
                            formData?.showIn &&
                            formData?.showIn.includes(item)
                          ) {
                            let vals = [...formData["showIn"]];
                            vals = vals.filter((val) => val !== item);
                            setFormData((prev: any) => ({
                              ...prev,
                              showIn: vals,
                            }));
                          } else {
                            setFormData((prev: any) => ({
                              ...prev,
                              showIn: [...prev.showIn, item],
                            }));
                          }
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={item.toString().replace(/ +/g, "_")}
                      >
                        <div className="small text-capitalize">{item}</div>
                      </label>
                    </div>
                  )
                )}
              </div>

              <h6>Featured</h6>
              <div className="border p-2 pb-3 mb-3">
                <div className="form-check mb-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="isFeatured"
                    checked={formData?.isFeatured}
                    disabled={loading}
                    onChange={(e: any) => {
                      setFormData((prev: any) => ({
                        ...prev,
                        isFeatured: e.target.checked,
                      }));
                    }}
                  />
                  <label className="form-check-label" htmlFor="isFeatured">
                    <div className="small text-capitalize">isFeatured</div>
                  </label>
                </div>
              </div>
              <h6>Season</h6>
              <div className="border p-2 mb-3">
                <div className=" mb-3">
                  <SelectFormGroup
                    htmlFor="season"
                    labelText="season"
                    name="season"
                    id="season"
                    defaultValue={formData?.season}
                    required={true}
                    onChange={handleChange}
                    list={["all season", "wet season", "dry season"]}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg-white p-3">
              <h6>Product image</h6>
              <div className="border  p-2 mb-3 d-flex gap-3">
                <div className="img_placeholder bg-color-1 py-3 px-4">
                  <div
                    style={{
                      background: `url(${
                        tempImage.url ||
                        formData?.imageUrl ||
                        "https://shorturl.at/dmxGW"
                      }) no-repeat scroll center / cover`,
                      width: "100px",
                      height: "100px",
                    }}
                  />
                </div>
                <label
                  htmlFor="uploadfile"
                  className="img_btn btn rounded-2 d-flex align-items-center flex-column justify-content-center py-3 px-4 "
                >
                  <Gallery size="25" color="#000000A2" />

                  <div className="small mt-2" style={{ fontSize: "11px" }}>
                    Click to browse
                  </div>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="uploadfile"
                  className="hide_input"
                  onChange={browseImage}
                  disabled={loading}
                />
              </div>
              <h6>Select Rim Size</h6>
              <div className="border p-2 mb-3">
                <div className=" mb-3">
                  <SelectFormGroup
                    htmlFor="rimsize"
                    labelText="rim size"
                    name="rimSize"
                    id="rimsize"
                    defaultValue={formData?.rimSize}
                    required={true}
                    onChange={handleChange}
                    list={rimSize}
                    disabled={loading}
                  />
                </div>
              </div>
              <h6>Price</h6>
              <div className="border p-2 mb-3">
                <InputFormGroup
                  htmlFor="price"
                  inputType="number"
                  labelText="Price"
                  name="price"
                  id="price"
                  min={0}
                  value={formData?.price}
                  placeholder="0"
                  required={true}
                  inputClass="form-control form-control-sm"
                  onChange={handleChange}
                  disabled={loading}
                />
                <InputFormGroup
                  htmlFor="discount price"
                  inputType="number"
                  labelText="Discount Price"
                  name="discountPrice"
                  id="discountPrice"
                  min={0}
                  value={formData?.discount}
                  placeholder={0}
                  inputClass="form-control form-control-sm"
                  onChange={handleChange}
                  disabled={loading}
                />
              </div>
              <h6>Inventory</h6>
              <div className="border p-2 pb-3 mb-3">
                <div className="mb-3">
                  <InputFormGroup
                    htmlFor="stock"
                    inputType="number"
                    labelText="Stock"
                    name="stock"
                    id="stock"
                    value={formData?.stock}
                    min={0}
                    required={true}
                    inputClass="form-control form-control-sm"
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>

                <SelectFormGroup
                  htmlFor="inventory"
                  labelText="Availability"
                  name="stock_availability"
                  id="stock_availability"
                  defaultValue={formData?.stock_availability}
                  required={true}
                  onChange={handleChange}
                  list={["in stock", "out of stock"]}
                  disabled={loading}
                />
              </div>

              <div className="d-flex align-items-center gap-4">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary-yellow btn-sm rounded-0 text-capitalize px-3"
                >
                  {!loading ? (
                    tyreToEdit?.id ? (
                      "Edit product"
                    ) : (
                      "add product"
                    )
                  ) : (
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      />
                      Please wait....
                    </div>
                  )}
                </button>

                <Link
                  to="/products"
                  className="btn btn-outline-primary-dark btn-sm rounded-0 text-capitalize px-3"
                >
                  discard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddProducts;
