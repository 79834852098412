import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { database } from "../dbConfig";
import { toast } from "react-toastify";
import { FirebaseError } from "firebase/app";
import { DeliveryLocation } from "../../model";
import { deliveryLocationType } from "../../types";
import { generateRandomNumbers } from "../functions";

export const getSettings = async (
  setSettings: React.Dispatch<React.SetStateAction<any>>
) => {
  try {
    onSnapshot(collection(database, "settings"), (snapshot) => {
      let settings = {};
      if (snapshot) {
        snapshot.forEach((doc: any) => {
          settings = {
            ...settings,
            [doc.id]: { ...doc.data() },
          };
        });
      }
      setSettings(settings);
    });
  } catch (error) {
    console.log(error);
  }
};

export const addPaymentOptionSettings = async (
  formData: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    await setDoc(doc(collection(database, "settings"), "paymentOptions"), {
      ...formData,
    });
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

export const updatePaymentOptionSettings = async (
  formData: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    console.log(formData);
    await updateDoc(doc(collection(database, "settings"), "paymentOptions"), {
      ...formData,
    });
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

export const addDeliveryMarkupFee = async (formData: any) => {
  try {
    await setDoc(doc(collection(database, "settings")), {
      deliveryFee: formData.deliveryFee,
      markup: formData.markup,
    });
  } catch (error) {}
};

export const updateDeliveryMarkupFee = async (
  formData: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    await updateDoc(doc(collection(database, "settings")), {
      deliveryFee: formData.deliveryFee,
      markup: formData.markup,
    });
  } catch (error: any) {
    toast.error(error.message);
    setLoading(false);
  }
};

export const addDeliveryLocations = async (
  location: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    const id = generateRandomNumbers(9);
    const locationData = new DeliveryLocation();

    location["id"] = id;

    await setDoc(doc(collection(database, "deliveryLocations"), id), {
      ...locationData.toDatabase(location),
    });
    setLoading(false);
  } catch (error) {
    setLoading(false);

    console.log(error);
  }
};

export const getDeliveryLocations = async (setDeliveryLocations: any) => {
  try {
    onSnapshot(collection(database, "deliveryLocations"), (snapshot: any) => {
      if (snapshot) {
        let locations: any = [];
        snapshot.forEach((doc: any) => {
          locations.push(new DeliveryLocation().fromDatabase(doc.data()));
        });
        setDeliveryLocations(locations);
      } else {
      }
    });
  } catch (error: any) {}
};

export const addExtraService = async (service: any) => {
  try {
    const id = generateRandomNumbers(7);
    await updateDoc(doc(collection(database, "settings"), `extraServices`), {
      [id]: {
        id,
        ...service,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateExtraService = async (service: any) => {
  try {
    await updateDoc(doc(collection(database, "settings"), `extraServices`), {
      [service.id]: {
        ...service,
      },
    });
  } catch (error) {
    console.log(error);
  }
};


export const updateSocialHandles = async (socials : any) => {
  try {

    await updateDoc(doc(collection(database,"settings"),"socials"), {
      ...socials
    });

  } catch (error) {
    console.log(error);
  }
}