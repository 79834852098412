import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAPMGL1tLJKQmbg79YLbkCrKwVir6LdK4c",
  authDomain: "mofyri.firebaseapp.com",
  projectId: "mofyri",
  storageBucket: "mofyri.appspot.com",
  messagingSenderId: "762745748895",
  appId: "1:762745748895:web:bed9601de6cb7f96d751aa",
};

const app = initializeApp(firebaseConfig); 

export const database = getFirestore(app);

export const storage = getStorage(app);

export const auth = getAuth(app);