import React from "react";

type inputTypes = {
  inputType: string;
  labelText?: string;
  htmlFor?: string;
  id: string;
  name: string;
  value?:any;
  required?: boolean,
  inputClass?: string;
  disabled?: boolean
  placeholder?: string | number | any;
  min?: number | null | any;

  onChange: (e: React.ChangeEvent) => void;
};
const InputFormGroup: React.FC<inputTypes> = ({
  htmlFor,
  inputType = "text",
  labelText,
  name,
  id,
  value = null,
  inputClass = "form-control form-control-sm",
  placeholder,
  min= null,
  required = false,
  disabled = false,
  onChange,
}) => {
  return (
    <div className="mb-3">
      {labelText &&  <label htmlFor={htmlFor} className="form-label small">
        {labelText}
      </label>}
     
      <input
        id={id}
        disabled={disabled}
        name={name}
        type={inputType}
        value={value}
        className={inputClass}
        placeholder={placeholder}
        min={min}
        required={required}
        onChange={onChange}
      />
    </div>
  );
};

export default InputFormGroup;
