

export const currentFormatter = (args: number) => {

    const formatter = Intl.NumberFormat('en-us',{
        currency:"UGX", style: "currency"
    });

    return formatter.format(args);

}

export const generateRandomNumbers = (len: number) => {
    const nums = "1234567890";
    let rand = "";

    for(let i = 0 ; i < len ; i++) {
        rand += nums[Math.floor(Math.random() * nums.length)];
    }

    return rand;
    
}